@import './bootstrap-material-design.css';
@import './config.scss';
@import './tip.scss';
@import './material-custom-checkbox-radio.scss';

@media screen {
	html {
		height: 100%;
		font-size: 16px;
		overflow: hidden;

		@include respond(over-xs) {
			font-size: 15px;
		}
	}

	body {
		height: 100%;
		overflow: auto;
	}

	html.no-touch :not(input):not(textarea),
	html.no-touch :not(input):not(textarea)::after,
	html.no-touch :not(input):not(textarea)::before {
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
}

@media print {
	body {
		min-width: auto !important;
	}
}

html,
body {
	padding: 0;

	/* overriding here */
	.btn.bmd-btn-icon {
		width: 2.6rem;
		height: 2.6rem;
	}

	.dropdown-menu {
		.material-icons {
			margin-right: 8px;
			font-size: 19px;
		}
	}
}

.modal {
	background: rgba(0, 0, 0, 0.6);
	display: block;
	overflow: auto;

	html.theme-dark & {
		background: rgba(0, 0, 0, 0.8);
	}
}

/* common */
// Checkbox.svelte
.category-tabs {
	display: flex;
	justify-content: center;

	button {
		margin-left: -1px;

		i {
			color: var(--mid);
		}

		&:not(.active) {
			background-color: transparent;
		}

		&.active {
			color: #eee !important;

			i {
				color: #eee !important;
			}
		}

		&.btn-outline-primary {
			&.active {
				background-color: var(--primary) !important;
			}
		}

		&.btn-outline-secondary {
			border-color: var(--border-secondary) !important;
			background: transparent;

			&.active {
				background-color: var(--border-secondary) !important;
			}
		}
	}

	&.category-tabs-secondary::before {
		border-top-color: rgba(108, 117, 125, 0.35);
	}
}

.btn.btn-modern {
	background: transparent;
	color: var(--txt-1);

	&.btn-raised {
		background: var(--txt-1);
		color: var(--bg-2);
	}
}

#app {
	width: 100vw;
	height: 100vh;
	background-color: var(--bg-1);
	color: var(--txt-2);
}

.btn.btn-block {
	padding-top: 0.8rem;
	padding-bottom: 0.8rem;
}

.modal-dialog {
	margin: 20px auto;
	width: 96%;
}

.modal-content .modal-footer {
	padding-left: 11px;
	padding-right: 11px;
	padding-bottom: 11px;
	margin-bottom: 0;
}

.form-control,
.custom-file-control {
	color: var(--txt-1) !important;
}

.form-control,
.form-control:read-only,
.custom-file-control,
.is-focused .form-control,
.is-focused .custom-file-control {
	background-image: linear-gradient(
			to top,
			var(--primary) 2px,
			rgba(0, 150, 136, 0) 2px
		),
		linear-gradient(to top, var(--low) 1px, rgba(0, 0, 0, 0) 1px);
}

.form-control:invalid,
.custom-file-control:invalid {
	background-image: linear-gradient(
			to top,
			#d50000 2px,
			rgba(213, 0, 0, 0) 2px
		),
		linear-gradient(to top, var(--low) 1px, rgba(0, 0, 0, 0) 1px);
}

.material-icons {
	color: var(--mid);
}

.modal-content,
.dropdown-menu {
	background: var(--bg-popup);
	color: var(--txt-1);
}

.dropdown-menu {
	box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
}

button.close > span {
	color: var(--txt-1);
	text-shadow: none;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
	border-color: var(--primary);
}

.btn.btn-outline-primary,
.btn-outline-primary.custom-file-control::before {
	border-color: var(--primary) !important;
	color: var(--primary);

	&:hover {
		color: var(--primary);
	}
}

.btn.bmd-btn-fab.btn-primary,
.btn.btn-raised.btn-primary,
.btn-group-raised .btn.btn-primary,
.bmd-btn-fab.btn-primary.custom-file-control::before,
.btn-raised.btn-primary.custom-file-control::before,
.btn-group-raised .btn-primary.custom-file-control::before {
	background-color: var(--primary);
	border-color: var(--primary);
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(0, 136, 255, 0.5);
}

.btn.bmd-btn-fab.btn-primary i,
.btn.btn-raised.btn-primary i,
.btn-group-raised .btn.btn-primary i {
	color: #fff;
}

.btn.bmd-btn-fab.btn-primary:hover,
.bmd-btn-fab.btn-primary.custom-file-control:hover::before,
.btn.btn-raised.btn-primary:hover,
.btn-raised.btn-primary.custom-file-control:hover::before,
.btn-group-raised .btn.btn-primary:hover,
.btn-group-raised .btn-primary.custom-file-control:hover::before {
	background-color: var(--primary-darken);
}

.dropdown-item {
	color: var(--txt-1);

	&:hover,
	&:focus {
		color: inherit;
		background: rgba(0, 0, 0, 0.1);
	}
}

.dropdown-divider {
	border-top-color: var(--border);
}

.header {
	height: 60px;
	padding-left: 10px;
	padding-right: 10px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 3px -2px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	top: 0;
	width: inherit;
	background: var(--bg-header);
	z-index: 100;

	.btn {
		margin-bottom: 0;
	}
}

.content {
	margin-top: 60px;
	height: 100%;
}

.btn.btn-outline-primary:hover,
.btn-outline-primary.custom-file-control:hover::before {
	color: var(--primary);
}

.btn.btn-primary {
	color: var(--primary);

	&:focus {
		color: var(--primary);
	}

	&:active,
	&.btn-raised {
		background: var(--primary) !important;
		color: #fff !important;

		&:focus {
			box-shadow: 0 0 0 0.2rem rgba(45, 134, 234, 0.5);
		}
	}

	&:hover {
		color: var(--primary);
	}
}

.bmd-label-static {
	margin-left: 5px;
	color: var(--txt-2);
	opacity: 0.4;
}

form,
.form {
	position: relative;

	button.btn-modern .spinner,
	button.btn-primary .spinner {
		display: none;
	}

	&.now-loading {
		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			opacity: 0.5;
			background: var(--bg-1);
		}

		button.btn-primary {
			color: transparent !important;
			position: relative;

			svg {
				display: block;
				position: absolute;
				left: 50%;
				top: 50%;
				margin-left: -12px;
				margin-top: -12px;
			}
		}
	}
}
