:root {
    --bg-1: #fff;
    --bg-2: #eaebf1;
    --bg-3: #ddd;
    --bg-header: #fff;
    --bg-pre: #23241f;
    --bg-selected: #d6d8de;
    --bg-popup: #fff;
    --bg-share: #0ba8af;
    --txt-1: #000;
    --txt-2: #333;
    --mid: #7e8082;
    --low: #d0d0d1;
    --anchor: #347cff;
    --border: #dcdcdc;
    --border-secondary: #999;
    --primary: #2d87ea;
    --primary-darken: #1c74d6;

    &.theme-dark {
        --bg-1: #191818;
        --bg-2: #000;
        --bg-3: #333;
        --bg-header: #2a2a2b;
        --bg-pre: #000;
        --bg-selected: #383838;
        --bg-popup: #242425;
        --txt-1: #fff;
        --txt-2: #e2e2e2;
        --mid: #9b9c9e;
        --low: #5a5b5c;
        --anchor: #347cff;
        --border: #373737;
        --border-secondary: #444;
    }
}

$break-xxxs: 340px;
$break-xxs: 480px;
$break-xs: 767px;
$break-sm: 991px;
$break-md: 1200px;
$break-lg: 1420px;
$break-xlg: 1740px;

@mixin respond($media) {
    @if $media==desktop-xs {
        @media only screen and (min-width: $break-sm + 1) and (max-width: 1350px) {
            body[data-nav='y'][data-responsive='md'].post-fixed & {
                @content;
            }
        }
    }

    @if $media==desktop-sm {
        @media only screen and (min-width: $break-md + 1) and (max-width: $break-lg) {
            body:not(.nav-folded).post-fixed & {
                @content;
            }
        }
    }

    @if $media==xxxs {
        @media only screen and (max-width: $break-xxxs) {
            @content;
        }
    }

    @if $media==xxs {
        @media only screen and (max-width: $break-xxs) {
            @content;
        }
    }

    @else if $media==xs {
        @media only screen and (min-width: $break-xxs + 1) and (max-width: $break-xs) {
            @content;
        }
    }

    @else if $media==sm {
        @media only screen and (min-width: $break-xs + 1) and (max-width: $break-sm) {
            @content;
        }
    }

    @else if $media==md {
        @media only screen and (min-width: $break-sm + 1) and (max-width: $break-md) {
            @content;
        }
    }

    @else if $media==mdlg {
        @media only screen and (min-width: $break-sm + 1) and (max-width: $break-lg) {
            @content;
        }
    }

    @else if $media==lg {
        @media only screen and (min-width: $break-md + 1) and (max-width: $break-lg) {
            @content;
        }
    }

    @else if $media==xlg {
        @media only screen and (min-width: $break-lg + 1) and (max-width: $break-xlg) {
            @content;
        }
    }

    @else if $media==xxlg {
        @media only screen and (min-width: $break-xlg + 1) {
            @content;
        }
    }

    @else if $media==under-xs {
        @media only screen and (max-width: $break-xs) {
            @content;
        }
    }

    @else if $media==under-sm {
        @media only screen and (max-width: $break-sm) {
            @content;
        }
    }

    @else if $media==under-md {
        @media only screen and (max-width: $break-md) {
            @content;
        }
    }

    @else if $media==under-lg {
        @media only screen and (max-width: $break-lg) {
            @content;
        }
    }

    @else if $media==over-xs {
        @media only screen and (min-width: $break-xxs + 1) {
            @content;
        }
    }

    @else if $media==over-sm {
        @media only screen and (min-width: $break-xs + 1) {
            @content;
        }
    }

    @else if $media==over-md {
        @media only screen and (min-width: $break-sm + 1) {
            @content;
        }
    }
}

@mixin overflow() {
    --webkit-overflow-scrolling: touch;
    -moz-overflow-scrolling: touch;
    -o-overflow-scrolling: touch;
    -ms-overflow-scrolling: touch;
    overflow-scrolling: touch;
}

@mixin absolute() {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

@mixin truncate() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin ellipsis() {
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin print() {
    @media only print {
        @content;
    }
}

@mixin screen() {
    @media only screen {
        @content;
    }
}

@mixin fullscreen() {

    html.fullscreen &,
    :fullscreen & {
        @content;
    }
}

.hidden {
    display: none !important;
}

.visible-print {
    display: none;
}

@media print {
    .hidden-print {
        display: none !important;
    }

    .visible-print {
        display: block !important;
    }
}