.material-custom-radio,
.material-custom-checkbox {
    cursor: pointer;
    position: relative;
    padding: 7px 0 7px 28px;
    font-size: 0.9rem;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:active {
        >span.check {
            background: rgba(0, 0, 0, 0.1);
        }
    }

    >span.check {
        display: inline-block;
        padding: 0;
        height: 30px;
        width: 30px;
        position: absolute;
        left: -6px;
        top: 2px;
        line-height: 1em;
        border-radius: 100%;

        >i {
            color: var(--primary);
            top: 3px;
            left: 4px;
            position: absolute;
        }
    }
}